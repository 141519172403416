<template>
	<div class="auth-success">
		<div class="icon"><i class="el-icon-warning"></i></div>
		<div class="tip">授权绑定失败</div>
		<div class="reason">
			<div style="display: inline-block;text-align: left;">
				<div style="margin-bottom: 5px;" class="reson-item">
          重新授权不可更换另一个小程序授权绑定，请选择现在已经绑定的小程序：
          <span style="color:#F56C6C">{{msg}}</span>, 
					<!-- <el-link type="primary" style="margin-left: 10px;" href="https://jusnn6k8al.feishu.cn/docs/doccn0ojpXlFsfWSqVraU8QuyMb" target="_blank">点击查看权限集说明</el-link> -->
				</div>
				<div class="reson-item">
					若你需要更换小程序授权绑定，请
					<el-popover width="200" trigger="hover">
						<div class="service-info" style="text-align: center;">
							<div style="font-size: 13px;font-weight: bold;margin-bottom: 5px;">添加专属客服</div>
							<div style="font-size: 12px;color: #666;margin-bottom: 5px;">一对一为您答疑解惑</div>
							<img style="width: 140px;" src="@/assets/img/20210719171848.jpg" />
						</div>
						<el-link slot="reference" type="primary" style="margin-left: 5px;">联系客服</el-link>
					</el-popover>
          协助操作。
				</div>
			</div>
			
		</div>
		<div style="margin-top: 80px;text-align: center;">
			<el-button type="primary" style="width: 200px;margin-right: 10px;" plain @click="jump">下次再授权</el-button>
			<el-button type="primary" style="width: 200px;" @click="auth">重新授权</el-button>
		</div>
		
	</div>
</template>

<script>
	import {
		wxPublicPlatFromInfo,
		mallFreeVersionConfigInfo
	} from '@/api/goods.js';
	
	export default {
		data() {
			return {
				msg: ''
			}
		},
		created() {
			if (this.$route.query.msg){
				this.msg = decodeURIComponent(this.$route.query.msg);
			}
			this.type = this.$route.query.type;
			// this.getmallFreeVersionConfigInfo()
			if (this.type == 1){
				this.getwxPublicPlatFromInfo();
			}else{
				this.getmallFreeVersionConfigInfo();
			}
		},
		methods: {
			async getwxPublicPlatFromInfo(){
				let res = await wxPublicPlatFromInfo()
				this.url = res.Result.AuthUrl;
			},
			async getmallFreeVersionConfigInfo(){
				let res = await mallFreeVersionConfigInfo()
				if (this.type == 2){
					this.url = res.Result.WxAppAuthUrl;
				}
				if (this.type == 3){
					this.url = res.Result.ManagerWxAppAuthUrl;
				}
				// console.log(res)
			},
			jump(){
				this.$router.replace({
					path: '/'
				})
			},
			auth(){
				// console.log(this.url)
				window.open(this.url)
			}
		},
	}
</script>

<style lang="less" scoped>
	.auth-success{
		.icon{
			font-size: 60px;
			text-align: center;
			color: #F56C6C;
			padding-top: 100px;
		}
		.tip{
			font-size: 20px;
			text-align: center;
			margin-top: 10px;
		}
		
		.reason{
			font-size: 14px;
			text-align: center;
			margin-top: 20px;
		}
		.reson-item{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
	}
</style>
